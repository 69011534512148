<template>
  <div class="user-follow">
    <div class="title">我的关注</div>
    <div class="content">
      <div class="user-follow_box" v-for="(follow, i) in followList" :key="i">
        <img class="user-follow_avatar" :src="follow.avatar" alt="" />
        <div class="user-follow_box-right">
          <div class="user-follow_name">{{ follow.nickName }}</div>
          <div class="user-follow_id-box">
            <i class="icon icon-live-id"></i>
            <span class="user-follow_id">{{ follow.roomId || '暂无直播ID' }} </span>
          </div>
          <a-popconfirm
            title="确认取消关注?"
            ok-text="确认"
            cancel-text="取消"
            @confirm="handleUnfollow(follow)"
            @cancel="handleCancel"
          >
            <div :class="['user-follow_unfollow', checkId === follow.id ? 'checked' : '']" @click="checkId = follow.id">
              取消关注
            </div>
          </a-popconfirm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import api from '@/services';

export default defineComponent({
  setup() {
    const state = reactive({
      followList: [],
      checkId: null
    });

    const getUserFollowList = async () => {
      try {
        let { success, data } = await api.userFollowList();
        if (success) state.followList = data;
      } catch (error) {
        console.error('我的关注列表接口错误！');
      }
    };

    // 取消关注
    const handleUnfollow = async params => {
      try {
        const { success } = await api.cancelFollow(params.id);
        if (success) {
          getUserFollowList();
          state.checkId = null;
        }
      } catch (error) {
        console.error('取消关注列表接口错误！');
      }
    };

    const handleCancel = () => {
      state.checkId = null;
    };

    onMounted(() => {
      getUserFollowList();
    });

    return {
      ...toRefs(state),
      getUserFollowList,
      handleUnfollow,
      handleCancel
    };
  }
});
</script>

<style lang="less" scoped>
@icon_live_id: '~@imgs/personal-center/icon_live_id.png';

.user-follow {
  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #007fff;
    line-height: 28px;
  }

  .content {
    margin-top: 38px;
  }

  &_box {
    margin: 10px 20px 10px 0;
    width: 270px;
    height: 100px;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    background: #f6f6f6;
    border-radius: 51px;
  }

  &_box-right {
    margin-left: 16px;
  }

  &_name {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
  }

  &_avatar {
    width: 100px;
    height: 100px;
    background: #007fff;
    border: 1px solid #007fff;
    border-radius: 50px;
    overflow: hidden;
  }

  &_id-box {
    height: 38px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .icon-live-id {
      width: 16px;
      height: 16px;
      background: url('@{icon_live_id}') no-repeat center / 100% 100%;
    }

    .user-follow_id {
      margin-left: 5px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 22px;
    }
  }

  &_unfollow {
    width: 68px;
    height: 20px;
    line-height: 20px;
    background: #d4d4d4;
    border-radius: 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    cursor: pointer;

    &.checked,
    &:hover {
      background: #007fff;
    }
  }
}
</style>
